import React from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import AssetCardMedia from './AssetCardMedia';

export default function Main(props){
    return(
        <div>
                <div style={{marginTop:10}} />
                            <Grid container direction={"row"} spacing={1}>
                                <Grid item md={6}>
                                <Card elevation = {0} style={{borderRadius:10, height:200, 
                                    borderWidth:1, borderStyle:"solid", borderColor:"#dcdcdc"}}>
                                <AssetCardMedia
                                sx={{ height: "100%" }}
                                uri ={props.listing.image1}
                            />
                                </Card>
                                </Grid>
                                <Grid item md={6}>
                                <Card elevation = {0} style={{borderRadius:10, height:200, 
                                    borderWidth:1, borderStyle:"solid", borderColor:"#dcdcdc"}}>
                                <AssetCardMedia
                                sx={{ height: "100%" }}
                                uri ={props.listing.image2}
                            />
                                </Card>
                                </Grid>
                            </Grid>
                            <div style={{marginTop:5}}/>
                            <Grid container direction={"row"} spacing={1}>
                                <Grid item md={6}>
                                {props.listing.image3 != null  &&
                                <Card elevation = {0} style={{borderRadius:10, height:200, 
                                borderWidth:1, borderStyle:"solid", borderColor:"#dcdcdc"}}>
                                <AssetCardMedia
                                sx={{ height: "100%" }}
                                uri ={props.listing.image3}
                            />
                                </Card>}
                                </Grid>
                                <Grid item md={6}>
                                    {props.listing.image4 != null && 
                                         <Card elevation = {0} style={{borderRadius:10, height:200, 
                                            borderWidth:1, borderStyle:"solid", borderColor:"#dcdcdc"}}>
                                       <AssetCardMedia
                                        sx={{ height: "100%" }}
                                        uri ={props.listing.image4}
                                    />
                                        </Card>
                                    }
                               
                                </Grid>
                            </Grid>
        </div>
    )
}