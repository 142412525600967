import React from 'react';
import {useDispatch} from 'react-redux';

import Grid  from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSelector } from "react-redux";
import styles from "../../styles/alerts";

import { Icon } from 'react-icons-kit'
import { home } from 'react-icons-kit/feather/home'
import {alertTriangle} from 'react-icons-kit/feather/alertTriangle';
import {xCircle} from 'react-icons-kit/feather/xCircle'
import {check} from 'react-icons-kit/feather/check';

import {clearNotify} from "../../actions";


export default function Main(){
  const [open, setOpen] = React.useState(true);

  const dispatch = useDispatch();
  const notify = useSelector(state => state.app.notify);
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    
    setOpen(false);
        dispatch(clearNotify())
    };

      return(
        <Snackbar open={notify.message}    anchorOrigin={{ vertical:"top", horizontal:"center" }} autoHideDuration={6000}  onClose={handleClose}>  
           <Alert onClose={handleClose} severity={notify.status}>
           {notify.message}
          </Alert>
        </Snackbar>
      )

}
