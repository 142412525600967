import React, { useEffect, useState } from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import AddIcon from '@mui/icons-material/Add';

import 'react-alice-carousel/lib/alice-carousel.css';


import {page} from '../../styles/main';
import { Typography, requirePropFactory } from '@mui/material';
import {getRealtors} from '../../actions/realtors';
import GridItem from '../../components/mobile/realtors/GridItem';




const classes = {
    root:{
        marginTop:10
    }
}

const styles = {
    media: {
      height: '100%',
      paddingTop: '75%', // 16:9,
      marginTop:'30'
    },
};






export default function Main(){
    const history = useHistory();
    const dispatch = useDispatch();
    const listings = useSelector(state => state.listing.listings);
    
    


    
    

 



    return(
      <div>
      <Grid style={{height:"100vh"}} justifyContent="center"  container direction="row">
        <Grid item xs={12} lg={10} sm={12} md={10} style={{marginTop:0, padding:10,}}>
        <div style={{marginTop:10}}/>
        <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <img style={{width:150}}  src={require("../../images/logo/1x/Asset 1.png")}/>
        </div>
        <div style={{marginTop:30}}/>

        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item md={6}>
              
              <Grid container direction="row" spacing={1} alignItems="flex-end">
                <Grid item>
                <Typography variant="h4" style={{fontFamily:"UberBold"}}>Realtors</Typography> {" "}
                </Grid>
              </Grid>
              
             
            
              
              </Grid>
             
            </Grid>

            <div style={{marginTop:20}} />
            
              <div style={{marginTop:20}} />
              <DataGrid  />
            
          
        
          </Grid>
          <Grid item md={4}>
          
          </Grid>
        </Grid>
      </Grid>
      </Grid>     
      </div>
    )
}

function DataGrid(props){
  const dispatch = useDispatch();
  const realtors = useSelector(state => state.realtor.realtors);
  const [data, setData] = useState(realtors ? realtors : []); 

  useEffect(() => {
    dispatch(getRealtors())
  }, [])

  useEffect(() => {
    setData(realtors ? realtors : [])
  }, [realtors])


  function handleFilter(searchText, location){
     
    if(location == "all"){
      setData(realtors.filter((item) => item.state != location).filter(item => item.name.includes(searchText))) 
    }else{
      setData(realtors.filter((item) => item.state == location).filter(item => item.name.includes(searchText)))
    }  
 }

  return(
      <>
      <Search update={(search, location) => handleFilter(search, location)} />
      <div style={{marginTop:20}} />
      <Grid container direction="row" spacing={1} >
      {data && data.map((item) => (
          <Grid item md={4} xs={12} sm={6}>
            <GridItem data={item} />
          </Grid>
      ))}
      </Grid>
      <div style={{marginTop:10, marginBottom:10, justifyContent:"center", display:"flex"}}>
      {data &&  data.length > 9 &&  <Pagination count={10} shape="rounded" />}
      </div>
     
      </>
      
  )
}

function Statistics(){
  return(
    <Grid container direction="row" justifyContent="space-between" spacing={1}>
      <Grid item md={4}>
        <Typography  variant="body1" color="textSecondary">Realtors</Typography>
        <Typography  variant="h4"  style={{fontFamily:"UberBold"}}>0</Typography>
      </Grid>
      <Grid item md={4}>
        <Typography  variant="body1" color="textSecondary">Listings</Typography>
        <Typography  variant="h4"  style={{fontFamily:"UberBold"}}>0</Typography>
      </Grid>
      <Grid item md={4}>
        <Typography  variant="body1" color="textSecondary">Requests</Typography>
        <Typography  variant="h4"  style={{fontFamily:"UberBold"}}>0</Typography>
      </Grid>
    </Grid>
  )
}


function Search(props){

  const [form, setForm] = useState({search: "", location:"all"});


  function handleChange(event){
    const {name, value} = event.target;
    if(name == "search"){
      props.update(value, form.location)
    }else{
      props.update(form.search, value)
    }
    setForm(form => ({...form, [name]: value}))
  }

  return(
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
        <TextField
          required
          fullWidth
          id="search"
          label="Search"
          defaultValue=""
          name="search"
          onChange={handleChange}
          variant="filled"
        />
        </Grid>
        <Grid item xs={4}>
        <TextField
          required
          fullWidth
          id="location"
          label="Location"
          select
          defaultValue="all"
          name="location"
          onChange={handleChange}
          variant="filled"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"lagos"}> Lagos </MenuItem>
          <MenuItem value={"abuja"}> Abuja </MenuItem>
        </TextField>
        </Grid>
        <Grid item md={2}>
         
        </Grid>
      </Grid>
    </div>
  )
}



