import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {BrowserRouter as Router, Switch, Route, Link, BrowserRouter} from "react-router-dom";


import Home from './mobile/main/Main';
import Realtor from './mobile/main/Realtor';
import Listing from './mobile/main/Listing'





const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

export default function Main(){
    return(
        <Mobile>
        <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/listing/:url" component={Listing} />
        <Route exact path="/:id" component={Realtor} />
        </Switch>
        </Mobile>
    )
}
