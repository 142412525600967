import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {BrowserRouter as Router, Switch, Route, Link, BrowserRouter} from "react-router-dom";
import Home from './web/main/Main';
import Realtor from './web/main/Realtor';
import Listing from './web/main/Listing';



const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export default function Main(){
    return(
        <Default>
            <Switch>
             <Route exact path="/" component={Home} />
             <Route exact path="/listing/:url" component={Listing} />
             <Route exact path="/:id" component={Realtor} />
            </Switch>
        </Default>
        
    )
}