export const WAIT = "WAIT";
export const NOTIFY = "NOTIFY";
export const AUTH = "AUTH";
export const INITIALIZE = "INITIALIZE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UNEXPECTED_ERROR = "UNEXPECTED_ERROR";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const SLOT = "SLOT";
export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const FETCH_PLACES = "FETCH_PLACES";
export const CLEAR_NOTIFY = "CLEAR_NOTIFY";
export const SWITCH_VIEW = "SWITCH_VIEW";