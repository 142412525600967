import {FETCH_REALTORS, FETCH_REALTOR} from  "../constants/realtors";


const initialState = {
  realtor:{
  },
  realtors:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_REALTOR:
      return Object.assign({}, state, {
         realtor: action.payload
       });
    case FETCH_REALTORS:
      return Object.assign({}, state, {
         realtors: action.payload
      });
  }
  return state;
}

export default reducer;
