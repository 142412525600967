import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KitchenIcon from '@mui/icons-material/Kitchen';
import BedIcon from '@mui/icons-material/Bed';
import ShowerIcon from '@mui/icons-material/Shower';
import LivingIcon from '@mui/icons-material/Living';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import GarageIcon from '@mui/icons-material/Garage';


import getSymbolFromCurrency from 'currency-symbol-map';
import AssetCardImage from '../../../components/image/portfolio/AssetCardMedia';
import {fetchListing} from '../../../actions/listings';


export default function Main(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const listing = props.data

    function handleClick(){
        dispatch(fetchListing(props.data));
        history.push("/listing/" + props.data.url);
    }

    
    return(
        <Card elevation={0} sx={{borderStyle:"solid", borderWidth:1, borderRadius:5,
        borderColor:"#E5E4E2" }}>
       <CardActionArea onClick={handleClick}>
       <AssetCardImage uri={listing.image1} />
       <CardContent>
           <Typography style={{fontFamily:"UberBold"}} variant="h6" noWrap component="div">
           {listing.title}
           </Typography>
           <Typography variant="body1" noWrap color="text.secondary">
           {listing.address}
           </Typography>
           <Typography variant="body1"><b>{getSymbolFromCurrency("NGN")}{listing.price}</b></Typography>


          


           <div style={{marginTop:10}}>
           
           
           <Divider  />

           <Grid container direction="row" style={{marginTop:10}}>
               <Grid item xs={6}>
                   <Grid container direction="row">
                       <Grid item>
                       <BedIcon />
                       </Grid>
                       <Grid item>
                       <Typography variant="body2" color="textSecondary">{listing.bedrooms} Bedrooms </Typography>
                       </Grid>
                   </Grid>
                  
               </Grid>
               <Grid item xs={6}>
                   <Grid container direction="row">
                       <Grid item>
                       <LivingIcon />
                       </Grid>
                       <Grid item>
                       <Typography variant="body2" color="textSecondary">{listing.livingrooms} Living Rooms</Typography>
                       </Grid>
                   </Grid>
                  
               </Grid>
               <Grid item xs={6}>
                   <Grid container direction="row">
                       <Grid item>
                           <ShowerIcon />
                       </Grid>
                       <Grid item>
                       <Typography variant="body2" color="textSecondary">{listing.bathrooms} Bathrooms</Typography>
                       </Grid>
                   </Grid>
                   
               </Grid>
               <Grid item xs={6}>
                   <Grid container direction="row">
                       <Grid item>
                           <KitchenIcon />
                       </Grid>
                       <Grid item>
                       <Typography variant="body2" color="textSecondary">{listing.kitchens} Kitchens</Typography>
                       </Grid>
                   </Grid>
                  
               </Grid>
               <Grid item xs={6}>
                   <Grid container direction="row">
                       <Grid item>
                           <SquareFootIcon />
                       </Grid>
                       <Grid item>
                       <Typography variant="body2"  color="textSecondary">{listing.total_area} sqft.</Typography>
                       </Grid>
                   </Grid>

                   
                  
               </Grid>

               <Grid item xs={6}>
               <Grid container direction="row">
                       <Grid item>
                           <GarageIcon />
                       </Grid>
                       <Grid item>
                       <Typography variant="body2"  color="textSecondary">{listing.garages} Garages</Typography>
                       </Grid>
                   </Grid>
               </Grid>
           </Grid>
           </div>
          
       </CardContent>
       
       </CardActionArea>
       
       </Card>
    )
}