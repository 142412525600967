import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Stack  from '@mui/material/Stack';
import KitchenIcon from '@mui/icons-material/Kitchen';
import BedIcon from '@mui/icons-material/Bed';
import ShowerIcon from '@mui/icons-material/Shower';
import LivingIcon from '@mui/icons-material/Living';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import GarageIcon from '@mui/icons-material/Garage';

export default function Main(props){
    const listing =  props.listing
    return(
        <div>
                <div style={{marginTop:10}} />
                    <Grid container direction="row">
                    <Grid item md={4}>
                    <Grid container direction="row">
                                    <Grid item>
                                        <SquareFootIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography  color="textSecondary">{listing.total_area} sqft.</Typography>
                                    </Grid>
                    </Grid>
                    </Grid>
                    <Grid item md={4}>
                    <Grid container direction="row">
                                    <Grid item>
                                    <BedIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography color="textSecondary">{listing.bedrooms} Bedrooms </Typography>
                                    </Grid>
                                </Grid>
                    </Grid>
                    <Grid item md={4}>
                    <Grid container direction="row">
                                    <Grid item>
                                        <ShowerIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography  color="textSecondary">{listing.bathrooms} Bathrooms</Typography>
                                    </Grid>
                                </Grid>
                    </Grid>
                                
                         <Grid item md={4}>
                         <Grid container direction="row">
                                    <Grid item>
                                    <LivingIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography  color="textSecondary">{listing.livingrooms} Living Rooms</Typography>
                                    </Grid>
                                </Grid>
                        </Grid>       
                                <Grid item md={4}>
                                <Grid container direction="row">
                                    <Grid item>
                                        <KitchenIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography  color="textSecondary">{listing.kitchens} Kitchens</Typography>
                                    </Grid>
                                </Grid>
                                </Grid>
                                
                                
                               
                    </Grid>
                    <div style={{marginTop:10}} />
        </div>
    )
}