import {FETCH_LISTING, FETCH_LISTINGS, FETCH_REQUEST} from  "../constants/listings";


const initialState = {
  listing:{
  },
  listings:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_LISTING:
      return Object.assign({}, state, {
         listing: action.payload
       });
    case FETCH_LISTINGS:
      return Object.assign({}, state, {
         listings: action.payload
      });
    case FETCH_REQUEST:
      return Object.assign({}, state, {
           request: action.payload
      });
  }
  return state;
}

export default reducer;
